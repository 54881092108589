import { useState } from "react";
import { ANIMATIONTIMER } from "../../Constants";


function ShowdownItem({ name, description, imageURL, animating }: {name: string, description: string, imageURL: string, animating: boolean}) {
    const [selectedItem, setSelectedItem] = useState(false);

    function clickRumbleItem() {
        if (!animating) {
            setSelectedItem(true);
            setTimeout(function () {
                setSelectedItem(false);
            }, ANIMATIONTIMER);
        }
    }

    return (
        <div style={{ backgroundImage: imageURL ? `url(${imageURL})` : ""}} className={`${selectedItem ? "animate" : "reset-animation"}`} onClick={clickRumbleItem}>
            <div className="ShowdownContent">
                <h3>
                    {name}
                </h3>
                <div>
                    {description}
                </div>
            </div>
        </div>
    );
}

export default ShowdownItem;
