import { faUpload, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef } from "react";
import { Button } from "reactstrap";
import { v4 as uuid } from "uuid";

export default function EditableThumbnail({ imageFile, imageURL, alt, setImage, removeImage}: { imageFile: File | null, imageURL: string | null, alt: string, setImage(imageFile: File): void, removeImage(): void}) {

    const idForInputs = useRef(`image-input-${uuid()}`);

    const imageUrlToUse = (imageFile && URL.createObjectURL(imageFile)) || imageURL;

    if (!imageUrlToUse) {
        return (
            <>
                <input type="file" id={idForInputs.current} style={{ display: "none" }} onChange={evt => {
                    if (evt.target.files && evt.target.files.length) {
                        setImage(evt.target.files[0])
                    }
                }} />
                <Button
                    color="primary"
                    onClick={() => document.getElementById(idForInputs.current)!.click()}
                    title="Add Image for Item (optional)"
                    style={{width: "100%", height: "100%", position: "inherit", top: "unset", right: "unset"}}
                >
                    <div>Add Image for Item (optional)</div>
                    <div><FontAwesomeIcon icon={faUpload} /></div>
                </Button>
            </>
        );
    }

    return (
        <div>
            <img className="thumbnail" src={imageUrlToUse!} alt={alt} />
            <div className="thumnailButtonHolder">
                <input type="file" id={idForInputs.current} style={{ display: "none" }} onChange={evt => {
                    if (evt.target.files && evt.target.files.length) {
                        setImage(evt.target.files[0])
                    }
                }} />
                <Button color="primary" className="mr-1" onClick={() => document.getElementById(idForInputs.current)!.click()} title="Choose a New Image for Item">
                    <FontAwesomeIcon icon={faUpload} />
                </Button>
                <Button color="danger" onClick={removeImage} title="Remove Image for Item">
                    <FontAwesomeIcon icon={faXmark} />
                </Button>
            </div>
        </div>
    );
}
