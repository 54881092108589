import { SET_UP_RUMBLE_STATE, UPDATE_RUMBLE_STATE_WITH_WINNER, ROLLBACK_RUMBLE_STATE } from "../actionTypes"
import { LEFT } from "../../Constants";

const initialState = {
    comparator: null,
    startingTime: null,
    rankingTimes: [],
    prevState: null
}

export default function(state = initialState, action) {
    switch (action.type) {
        case SET_UP_RUMBLE_STATE:
            const startingTime = new Date().getTime();
            const { comparatorConstructor, listItems } = action.payload;
            let comparator = new comparatorConstructor(listItems);
            comparator.initialize();
            return {comparator: comparator, prevState: null, startingTime: startingTime, rankingTimes: []};
        case UPDATE_RUMBLE_STATE_WITH_WINNER:
            const endingTime = new Date().getTime();
            let nextComparator = state.comparator.declareWinner(action.payload.winner);
            let nextRankingTimes = state.rankingTimes.map(rt => {
                return { ...rt };
            });
            if (action.payload.winner === LEFT) {
                nextRankingTimes.push({
                    WinningItemId: state.comparator.leftCompare.id,
                    LosingItemId: state.comparator.rightCompare.id,
                    DecisionTime: endingTime - state.startingTime
                });
            } else {
                nextRankingTimes.push({
                    WinningItemId: state.comparator.rightCompare.id,
                    LosingItemId: state.comparator.leftCompare.id,
                    DecisionTime: endingTime - state.startingTime
                });
            }
            return { prevState: state, comparator: nextComparator, startingTime: endingTime, rankingTimes: nextRankingTimes };
        case ROLLBACK_RUMBLE_STATE:
            if (state.prevState !== null) {
                return { ...state["prevState"], startingTime: new Date().getTime() };
            } else {
                return state;
            }
        default:
            return state;
    }
}