import { SET_ACTIVE_LIST } from "../actionTypes"

export default function (state = null, action) {
    switch (action.type) {
        case SET_ACTIVE_LIST:
            return action.payload.activeList
        default:
            return state;
    }
}
