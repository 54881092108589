import React, { useEffect, useState } from "react";

import { Container, Row, Col, Button, Input, InputGroup } from "reactstrap";

import { connect } from "react-redux";

import { Link, useHistory } from "react-router-dom";

import { getCurrentListInfo, isRumbleComplete, getComparator, getRankingTimes } from "../redux/selectors";

import RankedItem from "../components/RankedItem";
import { getCookieValue } from "../logic/Helpers";
import QrCodeWithDownload from "../components/QrCodeWithDownload";


function ListPage(props) {
    function copyListLink() {
        let copyButton = document.getElementById("copyButton");
        let listLink = document.getElementById("listLink");
        listLink.disabled = false;
        listLink.focus();
        listLink.select();
        listLink.setSelectionRange(0, 9999);
        document.execCommand("copy");
        copyButton.innerText = "copied";
        listLink.disabled = true;
        listLink.setSelectionRange(0, 0);
        listLink.blur();
        copyButton.disabled = true;
        setTimeout(resetCopyButton, 2000);
    }

    function resetCopyButton() {
        let copyButton = document.getElementById("copyButton");
        copyButton.innerText = "Copy";
        copyButton.disabled = false;
    }

    const history = useHistory();
    const [responseId, setResponseId] = useState(0);
    const [inError, setInError] = useState(false);
    const search = props.location.search;
    const participantId = new URLSearchParams(search).get("user");

    useEffect(
        () => {
            if (!props.isRumbleComplete || !props.comparator) {
                history.push("/");
            }

            let rankedItems = props.comparator.toList();
            let rankingTimes = props.rankingTimes;
            let saveData = {
                rankingTimes,
                rankedItems
            };
            if (participantId) {
                saveData.participantId = participantId;
            }

            let completed = null;
            if (typeof Storage !== "undefined") {
                completed = localStorage.getItem("completedLists");
            }
            let completedIds = [];
            if (completed) {
                completedIds = completed.split(",").map(parseInt);
            }

            if (completedIds.includes(props.currentList.id)) {
                let responseIds = null;
                if (typeof Storage !== "undefined") {
                    responseIds = localStorage.getItem("responseIds");
                }
                if (responseIds) {
                    const responseIdsMap = JSON.parse(responseIds);
                    if (props.currentList.id in responseIdsMap) {
                        setResponseId(responseIdsMap[props.currentList.id]);
                    }
                }
                return;
            }

            const xsrfToken = getCookieValue("XSRF-TOKEN");

            fetch(`/api/RumbleLists/SaveResults/${props.currentList.id}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "X-XSRF-TOKEN": xsrfToken,
                    },
                    body: JSON.stringify(saveData)
                })
                .then(res => {
                    if (res.ok && typeof Storage !== "undefined") {
                        let completed = localStorage.getItem("completedLists");
                        let completedIds = [];
                        if (completed) {
                            completedIds = completed.split(",");
                        }
                        completedIds.push(props.currentList.id);
                        localStorage.setItem("completedLists", completedIds.join(","));
                    }
                    return res.json();
                })
                .then(json => {
                    let responseIds = null;
                    if (typeof Storage !== "undefined") {
                        responseIds = localStorage.getItem("responseIds");
                    }
                    let responseIdsMap = {};
                    if (responseIds) {
                        responseIdsMap = JSON.parse(responseIds);
                    }
                    responseIdsMap[props.currentList.id] = json;
                    localStorage.setItem("responseIds", JSON.stringify(responseIdsMap));
                    setResponseId(json)
                })
                .catch(e => setInError(true));
        }, [props.comparator, history, props.isRumbleComplete]
    );

    const sharingLink = `${process.env["REACT_APP_BASE_URL"]}/share/${props.currentList.id}/${responseId}`;

    if (inError) {
        return (
            <Container className="text-center">
                <Row className="mb-4">
                    <Col>
                        <h1>An error occurred when submitting your results</h1>
                    </Col>
                </Row>
                <Row>
                    <div className="panel">
                        Try ranking the list again
                    </div>
                </Row>
                <Row className="match-panel">
                    <Col>
                        <Button tag={Link} to={`/Share/${props.currentList.id}`} block color="primary">Rank list again</Button>
                    </Col>
                </Row>
            </Container>
        );
    }

    if (!props.comparator || !responseId) {
        return null;
    }

    return (
        <Container className="text-center">
            <Row className="mb-4">
                <Col>
                    <h1>Here are your results for {props.currentList.name}</h1>
                </Col>
            </Row>
            <Row>
                <div className="panel">
                    {props.comparator.toList().map(i => {
                        return (
                            <RankedItem
                                key={i.id}
                                id={i.id}
                                title={i.name}
                                description={i.description}
                                imageURL={i.imageURL}
                                winCount={i.wins}
                                score={i.aggregatedPlaces} />
                        );
                    })}
                </div>
            </Row>
            <div className="panel">
                <Row>
                    <h2>Link to share your results</h2>
                </Row>
                <Row>
                    <Col>
                        <InputGroup>
                            <Input disabled id="listLink" value={sharingLink} />
                            <Button color="success" id="copyButton" onClick={copyListLink}>Copy</Button>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <h2>QR code to share your results</h2>
                </Row>
                <QrCodeWithDownload content={sharingLink} fileName={`RumbleRank_${props.currentList.name.replace(/[^\w]/g, "_")}_QR.png`} />
            </div>
            <Row className="panel">
                <Col>
                    Want to create your own Rumbles? <a href={`${process.env["REACT_APP_BASE_URL"]}/Login`}>Sign up for free.</a>
                </Col>
            </Row>
        </Container>
    );
}

function mapStateToProps(state) {
    return {
        isRumbleComplete: isRumbleComplete(state),
        currentList: getCurrentListInfo(state),
        comparator: getComparator(state),
        rankingTimes: getRankingTimes(state)
    };
}

export default connect(mapStateToProps, {})(ListPage);
