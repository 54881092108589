import saveAs from "file-saver";
import { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import QRCode from "qrcode";

export default function QrCodeWithDownload({ content, fileName }: {content: string, fileName: string}) {
    const [qrCodeData, setQrCodeData] = useState(null as string | null);
    useEffect(() => {
        async function generateQR() {
            setQrCodeData(await QRCode.toDataURL(content, { scale: 6 }));
        }
        generateQR();
    }, []);

    if (!qrCodeData) {
        return null;
    }

    return (
        <>
            <Row>
                <Col style={{ textAlign: "center" }}>
                    <img src={qrCodeData} alt="Sharing QR code" />
                </Col>
            </Row>
            <Row>
                <Col style={{ textAlign: "center" }}>
                    <Button color="primary" onClick={() => saveAs(qrCodeData, fileName)}>Download QR code image</Button>
                </Col>
            </Row>
        </>
    );
}
