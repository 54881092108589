import { useEffect, useState } from "react";

import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Alert, Container, Row, Col, Button, FormGroup, Input, Label } from "reactstrap";

import { connect } from "react-redux";

import { Link, useHistory } from "react-router-dom";

import { v4 as uuid } from "uuid";

import { setActiveList } from "../redux/actions";
import { getCurrentListInfo } from "../redux/selectors";
import { saveList } from "../logic/Helpers";
import Item from "../components/CreateList/Item";
import RumbleList from "../types/RumbleList";
import { CreateRumbleItem } from "../types/RumbleItem";
import { CreateParticipant } from "../types/Participant";
import { PARTICIPANTS_LIST_LIMIT } from "../Constants";

function CreateListPage(props: { currentList: RumbleList, setActiveList(list: RumbleList | null): void }) {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [image, setImage] = useState(null as File | null);
    const [imageURL, setImageURL] = useState(null as string | null);
    const [items, setItems] = useState([{ imageFile: null, imageURL: "", name: "", description: "", key: uuid() }] as CreateRumbleItem[]);
    const [participants, setParticipants] = useState([{ emailAddress: "", key: uuid() }] as CreateParticipant[]);
    const [type, setType] = useState("open");
    const [ready, setReady] = useState(false);
    const [canCreateLists, setCanCreateLists] = useState(false);
    const [showAllParticipants, setShowAllParticipants] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const history = useHistory();

    useEffect(() => {
        fetch("/api/Account/CanUserCreateLists")
            .then(resp => resp.json())
            .then(json => {
                setCanCreateLists(json);
                setReady(true);
            });
    }, []);

    useEffect(() => {
        if (props.currentList) {
            setName(props.currentList.name);
            setType(props.currentList.type.toLowerCase());
            setDescription(props.currentList.description);
            setImageURL(props.currentList.imageURL);
            setItems((props.currentList.items || []).map(item => ({ name: item.name, description: item.description, imageFile: null, imageURL: item.imageURL, key: uuid() })));
            setParticipants((props.currentList.participants || []).map(participant => ({ ...participant, key: uuid() })));
            props.setActiveList(null);
        }
    }, [props.currentList, props.setActiveList]);

    function updateItem(item: CreateRumbleItem) {
        setItems(oldItems => oldItems.map(i => i.key === item.key ? item : i));
    }

    function removeItem(key: string) {
        setItems(oldItems => oldItems.filter(item => item.key !== key));
    }

    function addNewItem() {
        setItems(oldItems => [{ name: "", description: "", imageFile: null, imageURL: "", key: uuid() }, ...oldItems]);
    }

    function updateParticipant(participant: CreateParticipant) {
        setParticipants(oldParticipants => oldParticipants.map(p => p.key === participant.key ? participant : p));
    }

    function removeParticipant(key: string) {
        setParticipants(oldParticipants => oldParticipants.filter(participant => participant.key !== key));
    }

    function addNewParticipant() {
        setParticipants(oldParticipants => [...oldParticipants, { emailAddress: "", key: uuid() }]);
    }

    function readParticipantList(inputFile: File) {
        let reader = new FileReader();
        reader.onloadend = function (event) {
            if (event.target?.readyState === FileReader.DONE) {
                let fileData = event.target.result as string;
                let emails = fileData.split("\n").map(line => {
                    return {
                        emailAddress: line.trim(),
                        key: uuid()
                    }
                });
                setParticipants(emails);
            }
        }
        reader.readAsText(inputFile);
    }

    async function handleListSave() {
        try {
            let listId = await saveList(name, description, image, imageURL, items, type, participants);
            history.push(`/ListDistributionInfo/${listId}`);
        } catch (e) {
            const eResp = e as Response;
            setShowErrorMessage(true);
            setErrorMessage(await eResp.text());
        }
    }

    let comparisonsInfo = null;

    if (items.length > 2) {
        comparisonsInfo =
            <Row className="mb-2">
                <Col>
                    <p>
                        {`In this rumble, participants will experience roughly ${Math.round(items.length * Math.log2(items.length))} matchups.`}
                    </p>
                </Col>
            </Row>;
    }

    if (!ready) {
        return <div>Loading...</div>
    }

    if (ready && !canCreateLists) {
        return (
            <Container className="text-center">
                <Row>
                    <Col>
                        <h1>Create a Rumble</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <a href="https://www.rumblerank.com/post/guide-to-rumblerank-account-setup-user-interface">How to use RumbleRank</a>
                    </Col>
                </Row>
                <Row>
                    <div className="panel">
                        <p>You have exceeded the number of active rumble for your account type. Go to <Link to="/Manage">your rumble management</Link> and delete some rumbles or contact Jordan to purchase an Unlimited account.</p>
                    </div>
                </Row>
                <Row className="match-panel">
                    <Col>
                        <Button tag={Link} to="/Profile" block color="secondary">Back</Button>
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <Container className="text-center">
            <Row>
                <Col>
                    <h1>Create a Rumble</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <a href="https://www.rumblerank.com/post/guide-to-rumblerank-account-setup-user-interface">How to use RumbleRank</a>
                </Col>
            </Row>
            <Row>
                <div className="panel">
                    <Row className="mb-4">
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="ListName">Name your rumble</Label>
                                <Input
                                    type="text"
                                    id="ListName"
                                    className="form-control"
                                    placeholder="Give Your List a Title for Your Participants"
                                    value={name}
                                    onChange={(evt) => setName(evt.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label>Choose a rumble type
                                <FormGroup>
                                    <Label>
                                        <Input
                                            type="radio"
                                            name="listTypeRadio"
                                            value="open"
                                            checked={type === "open"}
                                            onChange={(evt) => setType(evt.target.value)}
                                        />
                                        Sharing Link (We generate a link for you to share)
                                    </Label>
                                    <Label>
                                        <Input
                                            type="radio"
                                            name="listTypeRadio"
                                            value="closed"
                                            checked={type === "closed"}
                                            onChange={(evt) => setType(evt.target.value)}
                                        />
                                        Emailed Link (We email a link to participants you submit)
                                    </Label>
                                </FormGroup>
                            </Label>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="ListDescription">Describe your rumble</Label>
                                <Input
                                    type="text"
                                    id="ListDescription"
                                    className="form-control"
                                    placeholder="Tell Participants How To Rank Items"
                                    value={description}
                                    onChange={(evt) => setDescription(evt.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        {
                            (image || imageURL) && <Col xs={4}>
                                <Button
                                    color="danger"
                                    style={{ position: "absolute", top: "0.5em", right: "1.25em" }}
                                    onClick={() => { setImage(null); setImageURL(null); }}
                                    title="Remove image"
                                >
                                    <FontAwesomeIcon icon={faXmark} />
                                </Button>
                                {image ? <img className="thumbnail" src={URL.createObjectURL(image)} alt={`Thumbnail for ${name || "your new list"}`} /> :
                                    imageURL && <img className="thumbnail" src={imageURL} alt={`Thumbnail for ${name || "your new list"}`} />}
                            </Col>
                        }
                        <Col xs={(image || imageURL) ? 8 : 12}>
                            <FormGroup>
                                <Label for="ListImage">Give Your List a Cover Image (optional)</Label>
                                <Button block color="primary" onClick={() => { document.getElementById("ListImage")!.click() }}>Choose a Cover Image</Button>
                                <Input
                                    type="file"
                                    id="ListImage"
                                    className="form-control"
                                    placeholder="Image"
                                    style={{ display: "none" }}
                                    onChange={(evt) => evt.target.files && evt.target.files.length && setImage(evt.target.files[0])}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </Row>
            <Row>
                <div className="panel">
                    <Row className="mb-4">
                        <Col>
                            <h2>Add List Items</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button
                                color="success"
                                onClick={addNewItem}
                                block
                            >Add New Item To List</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ul className="mt-0">
                                {items.map((item) => {
                                    return (
                                        <Item key={item.key} item={item} disableDelete={items.length === 1} updateItem={updateItem} removeItem={removeItem} />
                                    );
                                })}
                            </ul>
                        </Col>
                    </Row>
                </div>
            </Row>
            {comparisonsInfo}
            {type === "closed" &&
                <Row>
                    <div className="panel">
                        <Row>
                            <Col>
                                <h2>Add List Participants</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="ParticipantList">
                                        Upload a list of participants
                                        <span className="help-text">Upload a .txt file with one email per line.</span>
                                    </Label>
                                    <Input
                                        style={{ display: "none" }}
                                        type="file"
                                        id="ParticipantList"
                                        className="form-control"
                                        onChange={(evt) => evt.target.files && evt.target.files.length && readParticipantList(evt.target.files[0])}
                                    />
                                    <Button block color="primary" onClick={() => document.getElementById("ParticipantList")!.click()}>Upload a list of participants</Button>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Col>
                            <span className="circle-highlight">
                                or
                            </span>
                        </Col>
                        <Row>
                            <Col>
                                <Label>
                                    Enter email addresses manually.
                                </Label>
                                <Button block color="success" onClick={addNewParticipant}>Add participant</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ul className="mt-0">
                                    {participants.map((p) => {
                                        return (<li key={p.key}>
                                            <Label style={{ width: "100%" }}>
                                                Email Address:
                                                <Input value={p.emailAddress} onChange={evt => updateParticipant({ ...p, emailAddress: evt.target.value })} />
                                            </Label>
                                            <Button color="danger" onClick={() => removeParticipant(p.key)} disabled={participants.length === 1}><FontAwesomeIcon icon={faXmark} /></Button>
                                        </li>);
                                    }).reverse().filter((p, index) => showAllParticipants || index < PARTICIPANTS_LIST_LIMIT)}
                                    {
                                        participants.length > PARTICIPANTS_LIST_LIMIT &&
                                        <ul>
                                            <Button
                                                block
                                                onClick={() => { setShowAllParticipants(s => !s) }}
                                                color="primary"
                                            >
                                                {showAllParticipants ? "Hide extra" : `Show all ${participants.length}`} participants
                                            </Button>
                                        </ul>
                                    }
                                </ul>
                            </Col>
                        </Row>
                    </div>
                </Row>
            }
            <Row className="match-panel">
                <Col>
                    <Alert color="danger" isOpen={showErrorMessage} toggle={() => setShowErrorMessage(false)}>{errorMessage}</Alert>
                </Col>
            </Row>
            <Row className="match-panel">
                <Col xs="6">
                    <Button tag={Link} to="/Profile" block color="secondary">Back</Button>
                </Col>
                <Col xs="6">
                    <Button className="save" block color="primary" onClick={handleListSave}>Save Rumble</Button>
                </Col>
            </Row>
        </Container>
    );
}

function mapStateToProps(state: any) {
    return {
        currentList: getCurrentListInfo(state)
    };
}

export default connect(mapStateToProps, { setActiveList })(CreateListPage);
