import { Container, Row, Col, Button } from "reactstrap"
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import CustomerPortalButton from "../components/Stripe/CustomerPortalButton";
import SubscribeButtons from "../components/Stripe/SubscribeButtons";

import { getUser, isUserReady } from "../redux/selectors";
import { setCurrentUser } from "../redux/actions";

function UpgradeAccountPage(props) {
    const history = useHistory();

    if (!(props.ready && props.user)) {
        return null;
    }

    if (props.user?.subscriptionTier === "Unlimited") {
        return (
            <div className="text-center">
			    <h1 className="mb-4">Upgrade your Account</h1>
			    <Container>
				    <div className="panel">
					    <Row>
                            <Col>
                                You have already upgraded your account to be unlimited.
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <CustomerPortalButton />
                            </Col>
                        </Row>
                    </div>
                    <Row className="match-panel">
                        <Col>
                            <Button tag={Link} to="Profile/Edit" block color="secondary">Back</Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

    return (
		<div className="text-center">
			<h1 className="mb-4">Upgrade your Account</h1>
			<Container>
				<div className="panel">
					<Row>
                        <Col>
                            <p className="mb-3">
                                The subscription cost for upgrading to an unlimited account
                                is $25 per month. You will no longer have restrictions on
                                how many Rumbles you can create and how many
                                responses you can view per rumble.
                            </p>
						</Col>
                    </Row>
                    <Row>
                        <Col>
                            <SubscribeButtons />
                        </Col>
                    </Row>
				</div>
				<Row className="match-panel">
					<Col>
                        <Button tag={Link} to="/Profile/Edit" block color="secondary">Back</Button>
					</Col>
				</Row>
			</Container>
		</div>
    );
}

function mapStateToProps(state) {
    return {
        user: getUser(state),
        ready: isUserReady(state)
    };
}

export default connect(mapStateToProps, { setCurrentUser })(UpgradeAccountPage);
