import { LEFT, RIGHT } from "../Constants";
import TreeNode from "./TreeNode";
import { shuffleArray } from "./Helpers";

class TreeComparator {
    constructor(objectsToCompare) {
        this.objectsToCompare = objectsToCompare;
        this.isDone = false;
    }

    initialize() {
        shuffleArray(this.objectsToCompare);
        this.tree = new TreeNode(this.objectsToCompare.pop());
        this.leftCompare = this.objectsToCompare.pop();
        this.activeTreeNode = this.tree;
        this.rightCompare = this.activeTreeNode.val;
    }

    declareWinner(winner) {
        let ntc = this.copy();
        if (winner === LEFT) {
            if (ntc.activeTreeNode.rchild !== null) {
                ntc.activeTreeNode = ntc.activeTreeNode.rchild;
                ntc.rightCompare = ntc.activeTreeNode.val;
            } else {
                ntc.activeTreeNode.setRightChild(ntc.leftCompare);
                ntc.activeTreeNode = ntc.activeTreeNode.getTreeRoot();
                ntc.rightCompare = ntc.activeTreeNode.val;
                if (ntc.objectsToCompare.length) {
                    ntc.leftCompare = ntc.objectsToCompare.pop();
                } else {
                    ntc.isDone = true;
                }
            }
        } else if (winner === RIGHT) {
            if (ntc.activeTreeNode.lchild !== null) {
                ntc.activeTreeNode = ntc.activeTreeNode.lchild;
                ntc.rightCompare = ntc.activeTreeNode.val;
            } else {
                ntc.activeTreeNode.setLeftChild(ntc.leftCompare);
                ntc.activeTreeNode = ntc.activeTreeNode.getTreeRoot();
                ntc.rightCompare = ntc.activeTreeNode.val;
                if (ntc.objectsToCompare.length) {
                    ntc.leftCompare = ntc.objectsToCompare.pop();
                } else {
                    ntc.isDone = true;
                }
            }
        }
        return ntc;
    }

    copy() {
        let ntc = new TreeComparator([...this.objectsToCompare]);
        ntc.tree = this.tree.copy();
        ntc.activeTreeNode = ntc.tree;
        let allNewNodes = ntc.tree.getAllNodes();
        for (let n of allNewNodes) {
            if (n.val === this.activeTreeNode.val) {
                ntc.activeTreeNode = n;
            }
        }
        ntc.tree = ntc.activeTreeNode.getTreeRoot();
        ntc.leftCompare = this.leftCompare;
        ntc.rightCompare = this.rightCompare;
        return ntc;
    }

    toList() {
        return this.tree.toList();
    }
}

export default TreeComparator;