import { useState, useEffect } from "react";

import { Container, Row, Col, Button } from "reactstrap";

import { useParams, useHistory } from "react-router-dom";

import RankedItem from "../components/RankedItem";
import RumbleItem from "../types/RumbleItem";
import RumbleList from "../types/RumbleList";

function ListWinners() {
    const { listId } = useParams<{listId: string}>();
    const [list, setList] = useState(null as RumbleList | null);
	let history = useHistory();

    useEffect(() => {
        fetch(`/api/RumbleLists/${listId}`)
            .then(resp => resp.json())
            .then(json => {
                setList(json);
            });
    }, [listId]);

    if (!list) {
        return <div className="loading-screen">Loading Rumble...</div>
	}

	function sortItems(a: RumbleItem, b: RumbleItem) {
		if (a.aggregatedPlaces === b.aggregatedPlaces) {
			return b.wins - a.wins;
		}
		return a.aggregatedPlaces - b.aggregatedPlaces;
	}

	return (
		<Container>
			<Row className="text-center mb-4">
				<Col>
					<h1>Overall rankings for {list.name}</h1>
				</Col>
			</Row>
			<Row>
				<div className="panel">
					{list.items.sort(sortItems).map(
						i => {
							return (
								<RankedItem
									key={i.id}
									id={i.id}
									title={i.name}
									description={i.description}
									imageURL={i.imageURL}
									winCount={i.wins}
                                    score={i.aggregatedPlaces}
                                    showScores
                                />
							);
						}
					)}

				</div>
			</Row>
			<Row className="match-panel">
				<Col>
					<Button
						block
						color="secondary"
						onClick={() => history.goBack()}
					>Back</Button>
				</Col>
			</Row>
		</Container>
	);
}

export default ListWinners;
