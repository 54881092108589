import React, { useState, useEffect } from "react";

import { Badge, Button, Container, Row, Col, Input, Label } from "reactstrap";

import { Link, Route, useHistory, useParams } from "react-router-dom";

import { connect } from "react-redux";

import { setActiveList } from "../redux/actions";

function AdminPage(props) {
    const params = useParams();
    const listId = params.listId && parseInt(params.listId);
	const [lists, setLists] = useState(null);
    const [activeListResponseCount, setActiveListResponseCount] = useState(null);
    const setActiveList = props.setActiveList;
    const history = useHistory();

    async function selectList(listId) {
        const listResponse = await fetch(`/api/RumbleLists/${listId}`);
        const participantsResponse = await fetch(`/api/RumbleLists/${listId}/Participants`);
        let list = await listResponse.json();
        let participants = await participantsResponse.json();
        list.participants = participants.map(p => ({ emailAddress: p.emailAddress }));
        setActiveList(list);
    }

    function chooseList(newListId) {
        history.push(`/Manage/${newListId}`);
        fetch(`/api/RumbleLists/${newListId}/ResponseCount`)
            .then(resp => resp.json())
            .then(json => setActiveListResponseCount(json));
    }

	useEffect(() => {
        fetch("/api/RumbleLists")
            .then(resp => resp.json())
            .then(json => {
                setLists(json);
            });
    }, []);

    useEffect(() => {
        if (listId && lists) {
            if (!lists.find(l => l.id === listId)) {
                history.push("/Manage");
            }
        }
    }, [listId, lists]);

    if (!lists) {
        return "Fetching your lists...";
    }

	return (
        <div className="text-center">
			<h1 className="mb-4">Manage your Rumbles</h1>
			<Container>
				<div className="panel">
					<Row>
						<Col>
							<Label for="listInput">Choose a rumble for more options.</Label>
							<Input
                                className="mb-4"
                                type="select"
                                id="listInput"
                                onChange={(evt) => chooseList(evt.target.value)}
                                value={listId}
							>
								<option value="">Choose a rumble</option>
								{lists.map(l => {
									return <option key={ l.id } value={l.id}>{l.name}</option>
								})}
							</Input>
						</Col>
					</Row>
                    <Route path="/Manage/:listId">
                        <Row>
                            <Col>
                                <Link to={`/ListDistributionInfo/${listId}`}>Rumble Distribution information</Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Link to={`/Winners/${listId}`}>Rumble Results</Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <span>
                                    <a href={`/api/DataExport/${listId}`}>Export rumble information</a>
                                    <Badge color="primary" className="ml-2">
                                        {activeListResponseCount ? `${activeListResponseCount} responses` : null}
                                    </Badge>
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Link to={`/CreateList`} onClick={() => selectList(listId)}>Duplicate Rumble</Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Link to={`/DeleteRumbleList/${listId}`}>Delete Rumble</Link>
                            </Col>
                        </Row>
                    </Route>
				</div>
				<Row className="match-panel">
					<Col>
                        <Button tag={Link} to="/Profile" block color="secondary">Back</Button>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default connect(null, { setActiveList })(AdminPage)
