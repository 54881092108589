import { useEffect, useState } from "react";

import { Button, Container, Row, Col } from "reactstrap";

import { Link, useParams } from "react-router-dom";

import ClosedListDistributionInfo from "../components/ClosedListDistributionInfo";
import OpenListDistributionInfo from "../components/OpenListDistributionInfo";
import Participant from "../types/Participant";
import RumbleList from "../types/RumbleList";

function ListDistributionPage() {
    const { listId } = useParams <{ listId: string }>();
    const [list, setList] = useState(null as RumbleList | null);
    const [participants, setParticipants] = useState([] as Participant[]);

    useEffect(() => {
        async function getListInfo() {
            let participantsPromise = fetch(`/api/RumbleLists/${listId}/Participants`);
            let listInfoPromise = fetch(`/api/RumbleLists/${listId}`);
            let results = await Promise.all([participantsPromise, listInfoPromise]);
            setParticipants(await results[0].json());
            setList(await results[1].json());
        }

        getListInfo();
    }, [listId])

    if (!list?.type) {
        return "loading";
    }

    return (
        <Container className="text-center">
            <Row>
                <Col>
                    <h1>Your Rumble Has Been Created!</h1>
                </Col>
            </Row>
            <Row>
                { list.type === "Closed" ? <ClosedListDistributionInfo participants={participants} /> : <OpenListDistributionInfo list={list} /> }
            </Row>
            <Row className="match-panel">
                <Col>
                    <Button tag={Link} to="/Manage" block color="secondary">Back</Button>
                </Col>
            </Row>
        </Container>
    );
}

export default ListDistributionPage;
