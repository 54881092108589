import { SET_CURRENT_USER } from "../actionTypes"

const initialState = {
    user: null,
    ready: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_USER:
            return { ...state, user: action.payload.user, ready: true };
        default:
            return state;
    }
}