class TreeNode {
	constructor(val) {
		this.val = val;
		this.lchild = null;
		this.rchild = null;
		this.parent = null;
	}

	totalNodes() {
		let lchildren = 0;
		let rchildren = 0;
		if (this.lchild) {
			lchildren = this.lchild.totalNodes();
		}
		if (this.rchild) {
			rchildren = this.rchild.totalNodes();
		}
		return lchildren + rchildren + 1;
	}

	depth() {
		let ldepth = 1;
		let rdepth = 1;
		if (this.lchild) {
			ldepth = this.lchild.depth() + 1;
		}
		if (this.rchild) {
			rdepth = this.rchild.depth() + 1;
		}
		return Math.max(ldepth, rdepth);
	}

	toList() {
		let llist = [];
		let rlist = [];
		if (this.lchild) {
			llist = this.lchild.toList();
		}
		if (this.rchild) {
			rlist = this.rchild.toList();
		}
		return [...rlist, this.val, ...llist];
	}

	getAllNodes() {
		let llist = [];
		let rlist = [];
		if (this.lchild) {
			llist = this.lchild.getAllNodes();
		}
		if (this.rchild) {
			rlist = this.rchild.getAllNodes();
		}
		return [...rlist, this, ...llist];
	}

	setLeftChild(value) {
		this.lchild = new TreeNode(value);
		this.lchild.parent = this;
	}

	setRightChild(value) {
		this.rchild = new TreeNode(value);
		this.rchild.parent = this;
	}

	getTreeRoot() {
		if (this.parent) {
			return this.parent.getTreeRoot();
		}
		return this;
	}

	copy() {
		let t = new TreeNode(this.val);
		if (this.lchild) {
			let lc = this.lchild.copy();
			lc.parent = t;
			t.lchild = lc;
		}
		if (this.rchild) {
			let rc = this.rchild.copy();
			rc.parent = t;
			t.rchild = rc;
		}
		return t;
	}
}

export default TreeNode;