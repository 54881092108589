import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { SOCIAL_IMAGE_STYLES } from "../Constants";
import RumbleList from "../types/RumbleList";

export default function ListDetailsPage() {
    const { id } = useParams<{ id: string, responseId: string }>();
    const [list, setList] = useState(null as RumbleList | null)
    const [fetched, setFetched] = useState(false);

    useEffect(() => {
        async function getList() {
            const listRequest = await fetch(`/api/RumbleLists/${id}/Participate`);
            if (listRequest.ok) {
                setList(await listRequest.json());
            }
            setFetched(true);
        }

        getList();
    }, []);

    if (!fetched) {
        return <div>Loading...</div>;
    }

    if (!list) {
        return <div>Not found</div>;
    }

    return (
        <Container className="text-center">
            <style>{SOCIAL_IMAGE_STYLES}</style>
            <h1>{list.name}</h1>
            <div>List created by {list.author}{list.authorCompany ? ` at ${list.authorCompany}` : null}</div>
            <div className="panel">
                <Row>
                    <Col xs={4}>
                        <img style={{maxHeight: "160px"}} src={list.imageURL} alt={`Thumbnail for ${list.name}`} />
                    </Col>
                    <Col xs={8}>
                        {list.description}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        This list contains {list.items.length} items. You will make about {Math.round(list.items.length * Math.log2(list.items.length))} head to head comparisons
                    </Col>
                </Row>
            </div>
            <h1>{`${process.env["REACT_APP_BASE_URL"]}/share/${id}`}</h1>
        </Container>
    );
}