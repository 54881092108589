import React, { useEffect, useState } from "react";
import Subscription from "../../types/Subscription";

export default function SubscribeButtons() {
    const [subscriptions, setSubscriptions] = useState([] as Subscription[]);
    const [publishableKey, setPublishableKey] = useState(undefined as string | undefined);

    useEffect(() => {
        async function getPublishableKey() {
            const getPublishableKeyResponse = await fetch("/api/Checkout/GetPublishableKey");
            setPublishableKey(await getPublishableKeyResponse.text());
        }
        getPublishableKey();
    }, []);

    useEffect(() => {
        async function getSubscriptions() {
            const subscriptionResponse = await fetch("/api/Checkout/ListProducts");
            setSubscriptions(await subscriptionResponse.json());
        }
        getSubscriptions();
    }, []);

    if (!publishableKey) {
        return null;
    }

    return (
        <>
            {subscriptions.map(s => {
                return (
                    <div key={s.id} className="mb-3">
                        <h3>{s.name}</h3>
                        <a href={`/api/Checkout/CreateSession/${s.defaultPrice}`}>Sign up</a>
                    </div>
                )
            })}
        </>
    );
}
