import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import { connect } from "react-redux";

import { getUser, isUserReady } from "../redux/selectors";

function ProfilePage(props) {
	const history = useHistory();
	const historyPush = history.push;
	useEffect(() => {
		if (props.ready && !props.user) {
			historyPush("/")
		}
	});

	if (!(props.ready && props.user)) {
		return null;
    }
	
	return (
		<div className="text-center">
			<h1 className="mb-4">{props.user.userName}'s Profile</h1>
			<Container>
				<div className="panel">
					<Row>
						<Col>
							<div>Account Type: {props.user.subscriptionTier}</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div>Email: {props.user.email}</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div>Company: {props.user.companyName}</div>
						</Col>
					</Row>
				</div>

				<Row className="mb-4 match-panel">
					<Col>
                        <Button tag={Link} to="/CreateList" block color="success">Create a Rumble</Button>
					</Col>
				</Row>
				<Row className="mb-4 match-panel">
					<Col>
                        <Button tag={Link} to="/Manage" block color="primary">Manage Rumbles</Button>
					</Col>
				</Row>
				<Row className="mb-4 match-panel">
					<Col>
                        <Button tag={Link} to="/Profile/Edit" block color="primary">Edit Profile/Upgrade</Button>
					</Col>
				</Row>
				<Row className="match-panel">
					<Col>
						<Button tag="a" href="/Logout" block>Log Out</Button>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

function mapStateToProps(state) {
	return {
		user: getUser(state),
		ready: isUserReady(state)
	};
}

export default connect(mapStateToProps, {})(ProfilePage);
