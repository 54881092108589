import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";

export default function CustomerPortalButton() {
    const [stripeLink, setStripeLink] = useState("");
    useEffect(() => {
        async function getStripeLink() {
            const stripeLinkResponse = await fetch("/api/Checkout/CustomerPortal", { method: "POST" });
            setStripeLink(await stripeLinkResponse.text());
        }
        getStripeLink();
    }, []);

    return (
        <>
            <Row className="mb-3">
                <Col>
                    <a href={stripeLink} className="mb-3">
                        <Button block disabled={stripeLink === ""} color="primary">Manage Subscription</Button>
                    </a>
                </Col>
            </Row>
        </>
    );
}
