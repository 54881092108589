import React from "react";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Input, Label, Row } from "reactstrap";
import { CreateRumbleItem } from "../../types/RumbleItem";
import EditableThumbnail from "./EditableThumbnail";

export default function Item({item, disableDelete, updateItem, removeItem }: { item: CreateRumbleItem, disableDelete: boolean, updateItem(item: CreateRumbleItem): void, removeItem(key: string): void }) {
    return (
        <li>
            <Row>
                <Col xs={4}>
                    <EditableThumbnail
                        imageFile={item.imageFile}
                        imageURL={item.imageURL}
                        setImage={imageFile => updateItem({ ...item, imageFile: imageFile, imageURL: "" })}
                        removeImage={() => updateItem({ ...item, imageFile: null, imageURL: "" })}
                        alt={`Thumbnail image for ${item.name}`}
                    />
                </Col>
                <Col xs={7}>
                    <Label style={{width: "100%"}}>
                        Name:
                        <Input value={item.name} onChange={evt => updateItem({ ...item, name: evt.target.value })} placeholder="Give This Item a Name" />
                    </Label>
                    <Label style={{ width: "100%" }}>
                        Description:
                        <Input value={item.description} onChange={evt => updateItem({ ...item, description: evt.target.value })} placeholder="Describe This Item" />
                    </Label>
                </Col>
                <Col xs={1}>
                    <Button color="danger" onClick={() => removeItem(item.key)} title="Remove item" disabled={disableDelete}><FontAwesomeIcon icon={faXmark} /></Button>
                </Col>
            </Row>
        </li>
    );
}
