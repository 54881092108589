export const LEFT = "LEFT";
export const RIGHT = "RIGHT";
export const ANIMATIONTIMER = 800;

export const SOCIAL_IMAGE_STYLES = `
        header {
            padding-bottom: 0;
            padding-top: 8px;
            height: 18vh;
        }
        .App {
            padding-top: 4px;
        }
        .panel {
            margin-top: 4px;
            max-width: 800px;
        }
        .panel li {
            padding-top: 4px;
            padding-bottom: 4px;
            overflow: unset;
        }
        .final-rank .thumbnail {
            max-height: 40px;
        }
    `;

export const PARTICIPANTS_LIST_LIMIT = 4;
