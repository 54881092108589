import React from "react";
import { Col, Row } from "reactstrap";
import RumbleItem from "../types/RumbleItem";
import RankedItem from "./RankedItem";

export default function ResultColumns({ column1, column2 }: { column1: RumbleItem[], column2: RumbleItem[] | null }) {
    const col1 = <Col xs={column2 ? 6 : 12}>
        <ol>
            {column1.map(i => <li>
                <RankedItem
                    key={i.id}
                    id={i.id}
                    title={i.name}
                    description={i.description}
                    imageURL={i.imageURL} />
            </li>)}
        </ol>
    </Col>;
    let col2 = null;
    if (column2) {
        col2 = <Col xs={6}>
            <ol>
                {column2.map(i => <li>
                    <RankedItem
                        key={i.id}
                        id={i.id}
                        title={i.name}
                        description={i.description}
                        imageURL={i.imageURL} />
                </li>)}
            </ol>
        </Col>;
    }
    return (
        <Row>
            {col1}{col2}
        </Row>
    );
}