import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, Container, Row, Col, Label, FormGroup, Input } from "reactstrap";
import { connect } from "react-redux";

import { getUser, isUserReady } from "../redux/selectors";
import { setCurrentUser } from "../redux/actions";

function EditProfilePage(props) {
	let [profileIncomplete, setProfileIncomplete] = useState(false);
	let [userName, setUserName] = useState("");
	let [companyName, setCompanyName] = useState("");
	let history = useHistory();

	async function saveProfile() {
		let response = await fetch("/api/Account/EditProfile", {
			method: "POST",
			headers: {"Content-Type": "application/json"},
			body: JSON.stringify({userName, companyName})
		});
		if (response.ok) {
			const newUserResp = await fetch("/api/Account/GetCurrentUser");
			if (newUserResp.ok) {
				const newUser = await newUserResp.json();
				props.setCurrentUser(newUser);
            }
			history.push("/Profile");
        }
	}

	useEffect(() => {
		if (props.ready && props.user) {
			setUserName(props.user.userName || "");
			setCompanyName(props.user.companyName || "");
			if (!props.user.hasAccount) {
				setProfileIncomplete(true);
            }
        }
	}, [props.user, props.ready]);

    let backButton = <Button tag={Link} to="/Profile" block>Back</Button>;

	let logoutButton = <Button tag="a" to="/logout" block>Log Out</Button>;


	if (!(props.ready && props.user)) {
		return null;
	}

	return (
		<Container className="text-center">
			<Row>
				<Col>
					<h1>{ profileIncomplete ? "Complete Your Profile" : "Edit Your Profile" }</h1>
				</Col>
			</Row>
			<Row>
				<Col>
					<FormGroup>
						<Label htmlFor="userName">User Name</Label>
						<Input
							id="userName"
							name="userName"
							value={userName}
							onChange={(evt) => setUserName(evt.target.value)}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col>
					<FormGroup>
						<Label htmlFor="companyname">Company Name</Label>
						<Input
							id="companyname"
							name="companyname"
							value={companyName}
							onChange={(evt) => setCompanyName(evt.target.value)}
						/>
					</FormGroup>
                </Col>
            </Row>
            <Row>
                <Col className="mb-3">

                    <Button
                        id="whitelabelButton"
                        tag={Link}
                        to="/Profile/WhiteLabel"
                        disabled={props.user.subscriptionTier !== "Unlimited"}
                        block
                        color="primary"
                    >
                        Set up Rumble branding
                    </Button>
                    {props.user.subscriptionTier !== "Unlimited" && <p>Upgrade to an Unlimited account to enable Rumble branding.</p>}
                </Col>
            </Row>
            <Row>
            </Row>
                <Row>
					<Col className="mb-3">
                        <Button tag={Link} to="/Profile/Upgrade" block color="success">Upgrade your Account!</Button>
					</Col>
				</Row>
			<Row>
				<Col xs="12" md="6" className="mb-3">
					<Button
						block
						color="primary"
						onClick={saveProfile}
					>
						Save Profile
					</Button>
				</Col>
				<Col xs="12" md="6">
					{ profileIncomplete ? logoutButton : backButton }
				</Col>
			</Row>
		</Container>
	);
}

function mapStateToProps(state) {
	return {
		user: getUser(state),
		ready: isUserReady(state)
	};
}

export default connect(mapStateToProps, {setCurrentUser})(EditProfilePage);
