import { SET_ACTIVE_LIST, SET_UP_RUMBLE_STATE, UPDATE_RUMBLE_STATE_WITH_WINNER, ROLLBACK_RUMBLE_STATE, SET_CURRENT_USER } from "./actionTypes"

export const setActiveList = activeList => ({
    type: SET_ACTIVE_LIST,
    payload: {
        activeList
    }
});

export const setupRumbleState = (comparatorConstructor, listItems) => ({
    type: SET_UP_RUMBLE_STATE,
    payload: {
        comparatorConstructor,
        listItems
    }
});

export const updateRumbleStateWithWinner = winner => ({
    type: UPDATE_RUMBLE_STATE_WITH_WINNER,
    payload: {
        winner
    }
});

export const rollbackRumbleState = () => ({
    type: ROLLBACK_RUMBLE_STATE
});

export const setCurrentUser = (user) => ({
    type: SET_CURRENT_USER,
    payload: {
        user
    }
});
