import { useState } from "react";

import { Button, Row, Col } from "reactstrap";
import { PARTICIPANTS_LIST_LIMIT } from "../Constants";
import Participant from "../types/Participant";

function ClosedListDistributionInfo({ participants }: {participants: Participant[]}) {
    const [showAllParticipants, setShowAllParticipants] = useState(false);

    return <div className="panel">
        <Row className="mb-4">
            <Col>
                <h2>Participant List</h2>
            </Col>
        </Row>
        <Row>
            <Col>
                <ul>
                    {participants.filter((p, index) => showAllParticipants || index < PARTICIPANTS_LIST_LIMIT).map(p => {
                        return <li> { p.emailAddress }</li>;
                    })}
                    {
                        participants.length > PARTICIPANTS_LIST_LIMIT &&
                        <li style={{ overflow: "visible" }}>
                                <Button
                                    block
                                    color="primary"
                                    onClick={() => setShowAllParticipants(s => !s)}
                                >
                                    {showAllParticipants ? "Hide extra" : `Show all ${participants.length}`} participants
                                </Button>
                        </li>
                    }
                </ul>
            </Col>
        </Row>
    </div>;
}

export default ClosedListDistributionInfo;
