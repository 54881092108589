import { useHistory } from "react-router-dom";

import { connect } from "react-redux";

import { getUser, isUserReady } from "../redux/selectors";

function RequireLogin(props) {
    let history = useHistory();
        
    if (props.ready && !props.user) {
        window.location.href = "/login";
        return null;
    }
    if (props.ready
        && props.user
        && !props.user.hasAccount
        && props.requireCompletedProfile) {
        history.push("/Profile/Edit");
    }

    return props.children;
}

function mapStateToProps(state) {
    return {
        user: getUser(state),
        ready: isUserReady(state)
    };
}

export default connect(mapStateToProps, {})(RequireLogin);