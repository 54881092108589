import { ReactNode } from "react";
import { Row, Col } from "reactstrap";

export default function RankedItem({ imageURL, title, id, description, showScores, winCount, score, children }: { imageURL: string, title: string, id: number, description: string, showScores?: boolean, winCount?: number, score?: number, children?: ReactNode }) {
	let thumbnail = null;
	if (imageURL) {
		thumbnail = <img className="thumbnail" src={imageURL} alt={`Thumbnail for ${title}`} />;
	}	

    return (
		<Row className="final-rank" key={id}>
			<Col>
				<Row>
                    <Col xs={ showScores ? 3 : 4}>
						{thumbnail}
                    </Col>
                    {showScores && < Col xs="3" style={{ display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
                        <div>
                            Wins: { winCount }
                        </div>
                        <div>
                            Score: { score }
                        </div>
                    </Col>}
                    <Col xs={showScores ? 6 : 8}>
						<h3>{title}</h3>
						<p className="description">
							{description}
						</p>
					</Col>
				</Row>
			</Col>
			{children}
		</Row>
    );
}
