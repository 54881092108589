import React, { useEffect, useState } from "react";

import { Button, Container, Row, Col } from "reactstrap";

import { Link, useHistory, useParams } from "react-router-dom";


function DeleteRumbleListPage() {
	const { listId } = useParams();
	const [list, setList] = useState(null);
	const history = useHistory();

	function deleteList(listId) {
		fetch(`/api/RumbleLists/${listId}`, {
			method: "DELETE"
		})
			.then(() => history.push("/Manage"));
	}

	useEffect(() => {
		fetch(`/api/RumbleLists/${listId}`)
			.then(res => res.json())
			.then(json => {
				setList(json);
			});
	}, [listId]);

	if (!list) {
		return (
			<div className="text-center">
				<Container>
					<div className="panel">
						<Row>
							<Col>
								Loading Rumble...
							</Col>
						</Row>
					</div>
					<Row className="match-panel">
						<Col>
                            <Button tag={Link} to="/Profile" block color="secondary">Back</Button>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}

	return (
		<div className="text-center">
			<h1 className="mb-4">Delete {list.name}</h1>
			<Container>
				<div className="panel">
					<Row>
						<Col>
							<p>Are you sure you want to delete {list.name}? It will be gone forever!</p>
							<p>Make sure you download any statistics you need from the <Link to="/Manage">manage page</Link> first.</p>
						</Col>
					</Row>
					<Row>
						<Col>
							<Button block color="danger" onClick={() => deleteList(listId)}>Delete Rumble</Button>
						</Col>
					</Row>
				</div>
				<Row className="match-panel">
					<Col>
                        <Button tag={Link} to="Manage" block color="secondary">Back</Button>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default DeleteRumbleListPage;
