import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, Col, Container, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";

export default function WhiteLabelPage() {
    const [leftColor, setLeftColor] = useState(null as string | null);
    const [rightColor, setRightColor] = useState(null as string | null);
    const [imageFile, setImageFile] = useState(null as File | null);
    const [imageURL, setImageURL] = useState(null as string | null);
    const [upgradeFirst, setUpgradeFirst] = useState(false);

    const history = useHistory();

    useEffect(() => {
        async function getWhiteLabelInfo() {
            const whiteLabelInfoResponse = await fetch("/api/Account/WhiteLabelInfo");
            if (!whiteLabelInfoResponse.ok) {
                setUpgradeFirst(true);
            }
            const whiteLabelInfo = await whiteLabelInfoResponse.json();
            setLeftColor(whiteLabelInfo.leftColor);
            setRightColor(whiteLabelInfo.rightColor);
            setImageURL(whiteLabelInfo.imageURL);
        }
        getWhiteLabelInfo();
    }, []);

    const saveSettings = useCallback(async () => {
        let imageURLToUse = imageURL;
        if (imageFile && !imageURL) {
            let listImageUploadData = new FormData();

            listImageUploadData.append("imageToUpload", imageFile, imageFile.name);

            let listImageUploadResp = await fetch("/api/ImageUpload", {
                method: "POST",
                body: listImageUploadData
            });

            let listImageUploadJson = await listImageUploadResp.json();

            imageURLToUse = listImageUploadJson.linkToFile;
        }
        await fetch("/api/Account/WhiteLabelInfo", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ leftColor, rightColor, imageURL: imageURLToUse })
        });
        history.push("/Profile/Edit");
    }, [leftColor, rightColor, imageFile, imageURL]);

    return (
        <Container className="text-center">
            <Row>
                <Col>
                    <h1>Configure Rumble branding</h1>
                </Col>
            </Row>
            <div className="panel">
                <Row>
                    <Label for="chooseImage" style={{ width: "100%" }}>Company Logo</Label>
                    <Col xs={4}>
                        {
                            (imageFile || imageURL) && <Button
                                color="danger"
                                style={{ position: "absolute", top: "0.5em", right: "1.25em" }}
                                onClick={() => { setImageFile(null); setImageURL(null)}}
                                title="Remove image"
                            >
                                <FontAwesomeIcon icon={faXmark} />
                            </Button>
                        }
                        {imageFile ? <img className="thumbnail" src={URL.createObjectURL(imageFile)} alt="Your company logo" /> :
                            imageURL ? <img className="thumbnail" src={imageURL} alt="Your company logo" /> :
                                <img className="thumbnail" src="/img/rr-logo.png" alt="RumbleRank logo" />}
                    </Col>
                    <Col xs={8}>
                        <FormGroup>
                            <Button id="chooseImage" block color="primary" onClick={() => { document.getElementById("ListImage")!.click() }}>Choose an image</Button>
                            <Input
                                type="file"
                                id="ListImage"
                                className="form-control"
                                placeholder="Image"
                                style={{ display: "none" }}
                                onChange={(evt) => evt.target.files && evt.target.files.length && setImageFile(evt.target.files[0])}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <input
                            type="color"
                            style={{ display: "none" }}
                            id="leftColorPicker"
                            onChange={evt => setLeftColor(evt.target.value)}
                        />
                        <Label
                            style={{ width: "100%" }}
                            for="leftColorPicker"
                        >
                            Left color
                        </Label>
                            <div
                                className="colorSelectPreview mb-2"
                                style={{ height: "20rem", backgroundColor: leftColor || "#db3639" }}
                            />
                            <Button
                                color="primary"
                                block
                                onClick={() => document.getElementById("leftColorPicker")!.click()}
                            >Choose left color</Button>
                            <Button
                                block
                                onClick={() => setLeftColor(null)}
                            >Reset</Button>
                    </Col>
                    <Col xs={6}>
                        <input
                            type="color"
                            style={{ display: "none" }}
                            id="rightColorPicker"
                            onChange={evt => setRightColor(evt.target.value)}
                        />
                        <Label
                            style={{ width: "100%" }}
                            for="rightColorPicker"
                        >
                            Right color
                        </Label>
                            <div
                                className="colorSelectPreview mb-2"
                                style={{ height: "20rem", backgroundColor: rightColor || "#3639db" }}
                            />
                            <Button
                                color="primary"
                                block
                                onClick={() => document.getElementById("rightColorPicker")!.click()}
                            >Choose right color</Button>
                            <Button
                                block
                                onClick={() => setRightColor(null)}
                            >Reset</Button>
                    </Col>
                </Row>
            </div>
            <div className="match-panel">
                <Row className="mb-3">
                    <Col>
                        <Button onClick={saveSettings} block color="primary">Save</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button tag={Link} to="/Profile/Edit" block color="secondary">Back</Button>
                    </Col>
                </Row>
            </div>
            <Modal isOpen={upgradeFirst}>
                <ModalHeader>
                    Upgrade First!
                </ModalHeader>
                <ModalBody>
                    <p>
                        Rumble branding is only available if you have an unlimited account.
                        Upgrade your account on the edit profile page before accessing this page.
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button block color="secondary" tag={Link} to="/Profile/Edit">Go to Edit Profile page</Button>
                </ModalFooter>
            </Modal>
        </Container>
    );
}
