import React, { useEffect, useState } from "react";
import AdSense from 'react-adsense';

import { Container, Row, Col, Button } from "reactstrap";

import { connect } from "react-redux";

import { Link, useParams } from "react-router-dom";

import { setActiveList } from "../redux/actions";
import { getCurrentListInfo } from "../redux/selectors";
import RankedItem from "../components/RankedItem";

function SharePage(props) {
    const { id, responseId } = useParams();
    const search = props.location.search;
    const participantId = new URLSearchParams(search).get("user");
    const numItemsInList = props.currentList?.items?.length || 0;

    let setActiveList = props.setActiveList;
    const [ready, setReady] = useState(false);
    const [inError, setInError] = useState(false);
    const [alreadyResponded, setAlreadyResponded] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [rankings, setRankings] = useState([]);

    useEffect(() => {
        async function setup() {
            let query = "";
            if (participantId) {
                query = `?participantId=${participantId}`;
            }
            let listResponse = await fetch(`/api/RumbleLists/${id}/Participate` + query);
            if (!listResponse.ok) {
                try {
                    let responseJson = await listResponse.json();
                    if (responseJson.reason === "AlreadyResponded") {
                        setAlreadyResponded(true);
                    } else {
                        setInError(true);
                        setErrorMessage("Could not get list information")
                    }
                    return;
                } catch (e) {
                    setInError(true);
                    setErrorMessage("Could not get list information");
                    return;
                }
            }
            let activeList = await listResponse.json();
            setActiveList(activeList);
            if (activeList.type === "Open" && typeof Storage !== "undefined") {
                let completedListsEntry = localStorage.getItem("completedLists");
                let completedLists = [];
                if (completedListsEntry) {
                    completedLists = completedListsEntry.split(",");
                }
                if (completedLists.includes(id)) {
                    setAlreadyResponded(true);
                }
                if (responseId) {
                    const rankingResponse = await fetch(`/api/RumbleLists/${id}/${responseId}`);
                    setRankings(await rankingResponse.json());
                }
            }
            setReady(true);
        }
        setup();
    }, [id, participantId, setReady, setInError, setErrorMessage, setActiveList, setAlreadyResponded]);

    let rankButton = <Button tag={Link} to={`/Showdown${participantId ? `?user=${participantId}` : ""}`} block color="primary">Rank!</Button>;

    if (alreadyResponded) {
        rankButton = <div>
            <Button block color="primary" disabled>Already Ranked</Button>
            <div className="mt-3">Thanks for your submission!</div>
        </div>;
    }

    if (inError) {
        return (
            <Container className="text-center">
                <Row className="mb-4">
                    <Col>
                        <h1>Error loading rumble!</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h2>{errorMessage}</h2>
                    </Col>
                </Row>
            </Container>
        );
    }

    if (!ready) {
        return <div className="loading-screen">loading...</div>
    }

    return (
        <Container className="text-center">
            <Row>
                <Col>
                    <h1>You've been invited to rank a rumble</h1>
                </Col>
            </Row>
            <Row>
                <div className="panel">
                    <Row>
                        <Col xs={4}>
                            <img className="thumbnail" src={props.currentList.imageURL} alt={`Thumbnail for ${props.currentList.name}`} />
                        </Col>
                        <Col xs={8}>
                            <h2>{props.currentList.name}</h2>
                            <p className="author">{`Created by ${props.currentList.author}${props.currentList.authorCompany ? ` at ${props.currentList.authorCompany}` : ""}`}</p>
                            <p>{props.currentList.description}</p>
                            <p>This list contains {numItemsInList} items. You will make about {Math.round(numItemsInList * Math.log2(numItemsInList))} head to head comparisons.</p>
                        </Col>
                    </Row>
                </div>
            </Row>
            {rankings.length > 0 && <div className="panel">
                <Row>
                    <Col>
                        <h2>Here's how I ranked {props.currentList.name}</h2>
                        {rankings.map(i => {
                            return (
                                <RankedItem
                                    key={i.id}
                                    id={i.id}
                                    title={i.name}
                                    description={i.description}
                                    imageURL={i.imageURL}
                                    winCount={i.wins}
                                    score={i.aggregatedPlaces} />
                            );
                        })}
                    </Col>
                </Row>
            </div>}
            <Row className="match-panel">
                <Col>
                    {rankButton}
                </Col>
            </Row>
            {props.currentList.showAds && <Row>
                <Col style={{ textAlign: "center" }}>
                    <AdSense.Google
                        client='ca-pub-2400035618862060'
                        slot='4877866478'
                        style={{ display: 'block' }}
                        layout='in-article'
                        format='fluid'
                    />
                </Col>
            </Row>}
        </Container>
    );
}

function mapStateToProps(state) {
    return {
        currentList: getCurrentListInfo(state)
    }
}

export default connect(mapStateToProps, { setActiveList })(SharePage)
