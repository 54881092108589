import WhiteLabelInfo from "../../types/WhiteLabelInfo";

export default function WhiteLabelOverride({ whiteLabelInfo }: { whiteLabelInfo: WhiteLabelInfo }) {
    const leftOverride = `
.RedCorner > div {
    background-color: ${whiteLabelInfo.leftColor};
}
@keyframes pulse-red {
    0% {
        transform: scale(1.05);
        box-shadow: 0 0 0 0 ${whiteLabelInfo.leftColor}B3;
    }

    40% {
        transform: scale(1.4);
    }

    80% {
        box-shadow: 0 0 0 200px ${whiteLabelInfo.leftColor}00;
        opacity: 1;
        transform: scale(1.4);
    }

    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 ${whiteLabelInfo.leftColor}00;
        opacity: 0;
    }
}
`;

    const rightOverride = `
.BlueCorner > div {
    background-color: ${whiteLabelInfo.rightColor}
}
@keyframes pulse-blue {
    0% {
        transform: scale(1.05);
        box-shadow: 0 0 0 0 ${whiteLabelInfo.rightColor}B3;
    }

    40% {
        transform: scale(1.4);
    }

    80% {
        box-shadow: 0 0 0 200px ${whiteLabelInfo.rightColor}00;
        opacity: 1;
        transform: scale(1.4);
    }

    100% {
        box-shadow: 0 0 0 0px ${whiteLabelInfo.rightColor}00;
        transform: scale(1);
        opacity: 0;
    }
}`;

    const styleOverride = `${whiteLabelInfo.leftColor ? leftOverride : ""}\n${whiteLabelInfo.rightColor ? rightOverride : ""}`;

    return <style>{styleOverride}</style>;
}
