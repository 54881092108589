const itemKeysToSend = ["description", "imageURL", "name"];
const participantKeysToSend = ["emailAddress"];

export async function saveList(name, description, image, imageURL, items, type, participants) {
	if (image && !imageURL) {
		let listImageUploadData = new FormData();

		listImageUploadData.append("imageToUpload", image, image.name);

		let listImageUploadResp = await fetch("/api/ImageUpload", {
			method: "POST",
			body: listImageUploadData
		});

        if (listImageUploadResp.status >= 400) {
            throw listImageUploadResp;
        }

		let listImageUploadJson = await listImageUploadResp.json();

		imageURL = listImageUploadJson.linkToFile;
    }

	let itemsWithImageUrls = await Promise.all(items.map(async i => {
        let itemImageURL = i.imageURL;
        if (i.imageFile && !itemImageURL) {
			let imageUploadData = new FormData();

			imageUploadData.append("imageToUpload", i.imageFile, i.imageFile.name);

			let imageUploadResp = await fetch("/api/ImageUpload", {
				method: "POST",
				body: imageUploadData
			});

            if (imageUploadResp >= 400) {
                throw imageUploadResp;
            }

			let imageUploadJson = await imageUploadResp.json();
			itemImageURL = imageUploadJson.linkToFile;
        }

        return { ...i, imageURL: itemImageURL };
	}));

	let listToSave = {
		type,
		name,
		description,
		imageURL,
        items: itemsWithImageUrls.map(item => Object.fromEntries(Object.keys(item).filter(key => itemKeysToSend.includes(key)).map(key => [key, item[key]]))),
        participants: participants.map(participant => Object.fromEntries(Object.keys(participant).filter(key => participantKeysToSend.includes(key)).map(key => [key, participant[key]])))
	};

    let saveListResponse = await fetch(`/api/RumbleLists`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(listToSave)
    });

    if (saveListResponse.status >= 400) {
        throw saveListResponse;
    }

	let saveListJson = await saveListResponse.json();

	return saveListJson.id;
}

/* Randomize array in-place using Durstenfeld shuffle algorithm */
export function shuffleArray(array) {
	for (var i = array.length - 1; i > 0; i--) {
		var j = Math.floor(Math.random() * (i + 1));
		var temp = array[i];
		array[i] = array[j];
		array[j] = temp;
	}
}

export const getCookieValue = (name) => (
  document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
)
