export function isRumbleComplete(state) {
    if (state.rumbleState.comparator) {
        return state.rumbleState.comparator.isDone;
    }
    return false;
}

export function hasPreviousState(state) {
    return state.rumbleState.prevState;
}

export function getComparator(state) {
    return state.rumbleState.comparator;
}

export function getLeftComparison(state) {
    if (state.rumbleState.comparator) {
        return state.rumbleState.comparator.leftCompare;
    }
    return null;
}

export function getRightComparison(state) {
    if (state.rumbleState.comparator) {
        return state.rumbleState.comparator.rightCompare;
    }
    return null;
}

export function getCurrentListInfo(state) {
    return state.list;
}

export function getRankingTimes(state) {
    return state.rumbleState.rankingTimes;
}

export function getUser(state) {
    return state.userStatus.user;
}

export function isUserReady(state) {
    return state.userStatus.ready;
}