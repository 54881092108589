import React, { useEffect } from "react";

import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";

import RumbleShowdownPage from "./pages/RumbleShowdownPage";
import ListPage from "./pages/ListPage";
import SharePage from "./pages/SharePage";
import CreateListPage from "./pages/CreateListPage";
import ListWinners from "./pages/ListWinners";
import ListDistributionPage from "./pages/ListDistributionInfoPage";
import HomePage from "./pages/HomePage";
import AdminPage from "./pages/AdminPage";
import ProfilePage from "./pages/ProfilePage";
import EditProfilePage from "./pages/EditProfilePage";
import RequireLogin from "./components/RequireLogin";
import DeleteRumbleListPage from "./pages/DeleteRumbleListPage";
import UpgradeAccountPage from "./pages/UpgradeAccountPage";
import ResultsPage from "./pages/ResultsPage";
import ListDetailsPage from "./pages/ListDetailsPage";
import WhiteLabelPage from "./pages/WhiteLabelPage";

import { connect } from "react-redux";

import { setCurrentUser } from "./redux/actions"
import { getUser, isUserReady, getCurrentListInfo } from "./redux/selectors";

function App(props) {

    useEffect(() => {
        async function getCurrentUser() {
            let resp = await fetch("/api/Account/GetCurrentUser");
            if (resp.ok) {
                let user = await resp.json();
                props.setCurrentUser(user);
            } else {
                props.setCurrentUser(null);
            }
        }
        getCurrentUser();
    }, []);

    return (
        <Router>
            <header>
                {props.ready && <Link to="/">
                    <img
                        src={props.currentList?.whiteLabelInfo?.imageURL || "img/rr-logo.png"}
                        alt={props.currentList?.whiteLabelInfo?.imageURL ? `${props.currentList.authorCompany} logo` : "RumbleRank Logo"}
                    />
                </Link>}
            </header>
            <div className="App">
                <Switch>
                    <Route exact path="/" render={(props) => <HomePage />} />
                    <Route path="/Showdown" component={RumbleShowdownPage} />
                    <Route path="/List" component={ListPage} />
                    <Route path="/Share/:id/:responseId?" component={SharePage} />
                    <Route path="/CreateList">
                        <RequireLogin>
                            <CreateListPage />
                        </RequireLogin>
                    </Route>
                    <Route path="/Winners/:listId" component={ListWinners} />
                    <Route path="/ListDistributionInfo/:listId">
                        <RequireLogin>
                            <ListDistributionPage />
                        </RequireLogin>
                    </Route>
                    <Route path="/Manage/:listId?">
                        <RequireLogin requireCompletedProfile>
                            <AdminPage />
                        </RequireLogin>
                    </Route>
                    <Route exact path="/Profile">
                        <RequireLogin requireCompletedProfile>
                            <ProfilePage currentUserStatus={{ user: props.user, ready: props.ready }} />
                        </RequireLogin>
                    </Route>
                    <Route path="/Profile/Edit">
                        <RequireLogin>
                            <EditProfilePage currentUserStatus={{user: props.user, ready: props.ready}} />
                        </RequireLogin>
                    </Route>
                    <Route path="/DeleteRumbleList/:listId">
                        <DeleteRumbleListPage />
                    </Route>
                    <Route path="/Profile/Upgrade">
                        <UpgradeAccountPage />
                    </Route>
                    <Route path="/Results/:id/:responseId">
                        <ResultsPage />
                    </Route>
                    <Route path="/Details/:id">
                        <ListDetailsPage />
                    </Route>
                    <Route path="/Profile/WhiteLabel">
                        <RequireLogin requireCompletedProfile>
                            <WhiteLabelPage />
                        </RequireLogin>
                    </Route>
                </Switch>
            </div>
        </Router>
    );
}

function mapStateToProps(state) {
    return {
        user: getUser(state),
        ready: isUserReady(state),
        currentList: getCurrentListInfo(state),
    };
}

export default connect(mapStateToProps, { setCurrentUser })(App);
