import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container } from "reactstrap";
import ResultsColumns from "../components/ResultsColumns";
import { SOCIAL_IMAGE_STYLES } from "../Constants";
import RumbleItem from "../types/RumbleItem";
import RumbleList from "../types/RumbleList";

export default function ResultsPage() {
    const { id, responseId } = useParams<{ id: string, responseId: string }>();
    const [list, setList] = useState(null as RumbleList | null);
    const [response, setResponse] = useState(null as RumbleItem[] | null);
    const [fetched, setFetched] = useState(false);

    useEffect(() => {
        async function getListAndResponse() {
            const listRequest = await fetch(`/api/RumbleLists/${id}/Participate`);
            if (listRequest.ok) {
                setList(await listRequest.json());
            }
            const responseRequest = await fetch(`/api/RumbleLists/${id}/${responseId}`);
            if (responseRequest.ok) {
                setResponse(await responseRequest.json());
            }
            setFetched(true);
        }

        getListAndResponse();
    },[]);

    if (!fetched) {
        return <div>Loading...</div>;
    }

    if (!list || !response) {
        return <div>Not Found</div>;
    }

    const totalItems = response.length;

    const firstColumn = response.slice(0, Math.min(totalItems, 5));
    const secondColumn = totalItems >= 10 ? response.slice(5, 10) : null;

    return (<Container className="text-center">
        <style>{ SOCIAL_IMAGE_STYLES }</style>
        <div>Here's how I ranked</div>
        <h1 style={{marginTop: 0}}>{list.name}</h1>
        <div className="panel">
            <ResultsColumns column1={firstColumn} column2={secondColumn} />
        </div>
        <h1>{`${process.env["REACT_APP_BASE_URL"]}/share/${id}/${responseId}`}</h1>
    </Container>);
}