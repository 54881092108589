import { Input, InputGroup, Button, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import RumbleList from "../types/RumbleList";
import QrCodeWithDownload from "./QrCodeWithDownload";

function OpenListDistributionInfo({ list }: {list: RumbleList}) {
    function copyListLink() {
        let copyButton = document.getElementById("copyButton") as HTMLButtonElement;
        let listLink = document.getElementById("listLink") as HTMLInputElement;
        listLink.disabled = false;
        listLink.focus();
        listLink.select();
        listLink.setSelectionRange(0, 9999);
        document.execCommand("copy");
        copyButton.innerText = "copied";
        listLink.disabled = true;
        listLink.setSelectionRange(0, 0);
        listLink.blur();
        copyButton.disabled = true;
        setTimeout(resetCopyButton, 2000);
    }

    function resetCopyButton() {
        let copyButton = document.getElementById("copyButton") as HTMLButtonElement;
        copyButton.innerText = "Copy";
        copyButton.disabled = false;
    }

    const shareLink = `${process.env["REACT_APP_BASE_URL"]}/share/${list.id}`;

    return <div className="panel">
        <Row className="mb-4">
            <Col>
                <h2>Link to share this rumble</h2>
            </Col>
        </Row>
        <Row>
            <Col>
                <InputGroup>
                    <Input disabled id="listLink" value={shareLink} />
                    <Button color="success" id="copyButton" onClick={ copyListLink }>Copy</Button>
                </InputGroup>
            </Col>
        </Row>
        <Row className="mb-4">
            <Col>
                <Button tag={Link} to={`/share/${list.id}`} color="primary" block>Rank it yourself</Button>
            </Col>
        </Row>
        <Row className="mb-4">
            <Col>
                <h2>QR code to share this rumble</h2>
            </Col>
        </Row>
        <QrCodeWithDownload content={shareLink} fileName={`RumbleRank_${list.name.replace(/[^\w]/g, "_")}_QR.png`} />
    </div>;
}

export default OpenListDistributionInfo;
