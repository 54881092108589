import React, { useState, useEffect } from "react";
import AdSense from 'react-adsense';

import { Container, Row, Col, Button } from "reactstrap";

import { connect } from "react-redux";

import { useHistory } from "react-router-dom";

import TreeComparator from "../logic/TreeComparator";
import { setupRumbleState, updateRumbleStateWithWinner, rollbackRumbleState } from "../redux/actions";
import { getLeftComparison, getComparator, getRightComparison, getCurrentListInfo, isRumbleComplete, hasPreviousState, getRankingTimes } from "../redux/selectors";
import ShowdownItem from "../components/Showdown/ShowdownItem";
import { LEFT, RIGHT, ANIMATIONTIMER } from "../Constants";
import WhiteLabelOverride from "../components/Showdown/WhiteLabelOverride";

function RumbleShowdownPage(props) {
    const [ready, setReady] = useState(false);
    const [animating, setAnimating] = useState(false);
	const search = props.location.search;
	const participantId = new URLSearchParams(search).get("user");

	let setupRumbleState = props.setupRumbleState;

	let history = useHistory();

	useEffect(() => {
		if (props.currentList === null) {
			history.push("/");
		}

		if (props.isRumbleComplete) {
			let redirect = "/List";
			if (participantId) {
				redirect += `?user=${participantId}`;
            }
			history.push(redirect);
		}

		if (ready) {
			return;
		}

		setReady(true);
		setupRumbleState(TreeComparator, props.currentList.items);
	}, [props.currentList, props.comparator, props.isRumbleComplete, history, participantId, ready, setupRumbleState, props.rankingTimes]);

	if (!ready) {
		return <div>Setting up rumble</div>;
	}

	if (props.isRumbleComplete) {
		return null;
    }

	return (
        <Container fluid={true} className="h-100 p-2">
            <WhiteLabelOverride whiteLabelInfo={props.currentList.whiteLabelInfo} />
			<Row className="mb-4 h-100 ShowdownHolder">
                <div
                    className="ShowdownCorner RedCorner"
                    onClick={() => {
                        if (!animating) {
                            setAnimating(true);
                            setTimeout(function () {
                                props.updateRumbleStateWithWinner(LEFT);
                                setAnimating(false);
                            }, ANIMATIONTIMER);
                        }
                    }}
                >
					<ShowdownItem
                        name={props.leftComparison.name}
                        description={props.leftComparison.description}
                        imageURL={props.leftComparison.imageURL}
                        animating={animating}
					/>
				</div>
				<div
                    className="ShowdownCorner BlueCorner"
                    onClick={() => {
                        if (!animating) {
                            setAnimating(true);
                            setTimeout(function () {
                                props.updateRumbleStateWithWinner(RIGHT);
                                setAnimating(false);
                            }, ANIMATIONTIMER);
                        }
                    }}
                >
					<ShowdownItem
                        id={props.rightComparison.id}
                        name={props.rightComparison.name}
                        description={props.rightComparison.description}
                        imageURL={props.rightComparison.imageURL}
                        animating={animating}
					/>
				</div>
			</Row>
			{
				props.hasPreviousState ?
				<Row>
					<Col>
						<Button color="light" onClick={props.rollbackRumbleState}>Previous Question</Button>
					</Col>
				</Row>
				: null
            }
            {
                props.currentList.showAds &&
                <Row>
                    <Col style={{ textAlign: "center" }}>
                        <AdSense.Google
                            client='ca-pub-2400035618862060'
                            slot='4877866478'
                            style={{ display: 'block' }}
                            layout='in-article'
                            format='fluid'
                        />
                    </Col>
                </Row>
            }
		</Container>
	);
}

function mapStateToProps(state) {
	return {
		comparator: getComparator(state),
		isRumbleComplete: isRumbleComplete(state),
		hasPreviousState: hasPreviousState(state),
		leftComparison: getLeftComparison(state),
		rightComparison: getRightComparison(state),
		currentList: getCurrentListInfo(state),
		rankingTimes: getRankingTimes(state)
	};
}

export default connect(mapStateToProps, { setupRumbleState, updateRumbleStateWithWinner, rollbackRumbleState })(RumbleShowdownPage);
