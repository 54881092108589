import React, { useEffect, useState } from "react";

import { Container, Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { getUser, isUserReady } from "../redux/selectors";

function HomePage(props) {

	let [homeContents, setHomeContents] = useState(
		<Container>
			<Row className="mb-4">
				<Col xs={12} md={6} className="mb-4">
					Loading...
					</Col>
			</Row>
		</Container>);

	useEffect(() => {
		if (props.ready) {
			if (props.user) {
				setHomeContents(<Container>
					<Row className="mb-4">
						<Col xs={12} md={6} className="mb-4">
                            <Button tag={Link} to="/Profile" block>Profile</Button>
						</Col>
						<Col xs={12} md={6} className="mb-4">
							<Button tag="a" href="/Logout" block>Log Out</Button>
						</Col>
					</Row>
				</Container>);
			} else {
				setHomeContents(<Container>
					<Row className="mb-4">
						<Col>
							<Button tag="a" href="/Login" block>Log In or Sign Up</Button>
						</Col>
					</Row>
				</Container>
				);
			}
        } 
	}, [props.user, props.ready]);

	return (
		<div className="text-center">
            {homeContents}
            <iframe className="match-panel" style={{ width: "100%", aspectRatio: "16/9" }} src="https://www.youtube.com/embed/pJmiBvOOiUM" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
		</div>
	);
}

function mapStateToProps(state) {
	return {
		user: getUser(state),
		ready: isUserReady(state)
	};
}

export default connect(mapStateToProps, {})(HomePage);
